// Generated by Framer (48da836)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-yw7Lm"

const variantClassNames = {wFbUwrrSP: "framer-v-1e3yayx"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, W2qCdlMXj: title ?? props.W2qCdlMXj ?? "AI"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, W2qCdlMXj, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wFbUwrrSP", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1e3yayx", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"wFbUwrrSP"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTUwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "0.03em", "--framer-line-height": "1.3em", "--framer-text-color": "var(--extracted-r6o4lv, rgb(80, 72, 229))"}}>AI</motion.p></React.Fragment>} className={"framer-1x47lgk"} data-framer-name={"Text"} fonts={["GF;Instrument Sans-500"]} layoutDependency={layoutDependency} layoutId={"LVVBpHfXH"} style={{"--extracted-r6o4lv": "rgb(80, 72, 229)", "--framer-paragraph-spacing": "0px"}} text={W2qCdlMXj} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yw7Lm.framer-o4bedv, .framer-yw7Lm .framer-o4bedv { display: block; }", ".framer-yw7Lm.framer-1e3yayx { height: 21px; overflow: visible; position: relative; width: 91px; }", ".framer-yw7Lm .framer-1x47lgk { flex: none; height: auto; left: 49%; position: absolute; top: 48%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 91
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W2qCdlMXj":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerkYt4YuwqU: React.ComponentType<Props> = withCSS(Component, css, "framer-yw7Lm") as typeof Component;
export default FramerkYt4YuwqU;

FramerkYt4YuwqU.displayName = "Text 2";

FramerkYt4YuwqU.defaultProps = {height: 21, width: 91};

addPropertyControls(FramerkYt4YuwqU, {W2qCdlMXj: {defaultValue: "AI", displayTextArea: false, placeholder: "", title: "Title", type: ControlType.String}} as any)

addFonts(FramerkYt4YuwqU, [{explicitInter: true, fonts: [{family: "Instrument Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-QfwmS0v3_7Y.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})